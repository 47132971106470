import React, { useState} from 'react'; //, useContext, useEffect 
import { Label, Form, Input, Button} from 'reactstrap'; //, InputGroup, InputGroupText 
//import { isAccessor } from 'typescript';
//import { SEND_MAIL_ERROR, SEND_MAIL_LOADING, SEND_MAIL_SUCCESS } from "../constants/actionTypes";
//import axiosInstance from "../helpers/Axios";
import { register as registerUser } from '../context/actions/auth/register';
//import { GlobalContext } from '../context/Provider';
import IContact from '../interfaces/IContact';
import '../css/registrationform.scss';


interface ICourse {
    shortName: string;
    name: string;
    day: IDay;
    isVisible: boolean;
    startTime: string;
    endTime: string;
  }

  interface IStage {
    shortName: string;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
  }
  
  interface IDay {
    name: string;
  }

  interface ICourseObj {
    course: ICourse;
    subscribes: IContact[];
  }

interface IProps {
    courses: undefined | ICourseObj[];
    stages: undefined | IStage[];
    title: string;
}


interface IRegistrationForm {
    course: string;
    stage: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    phoneNumber: string;
    // address: string;
    street: string;
    postalCode: string;
    city: string;
    emailAddress: string;
    emailAddress1: string;
    emailAddress2: string;
    isachild: boolean;
    }

const defaultRegistrationForm = {
    course: "",
    stage: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    phoneNumber: "",
    // address: "",
    street: "",
    postalCode: "",
    city: "",
    emailAddress: "",
    emailAddress1: "",
    emailAddress2: "",
    isachild: true
}

const niceTime = (time: string) => {
    if (time === null)
        return '';
    const tokens = time.split(':');
    return `${tokens[0]}h${tokens[1]}`;
  };

  const monthToString = (month: number) => {
    const monthList = ['Janvier', 'Février', 'Mars',
    'Avril','Mai','Juin',
    'Juillet','Août','Septembre',
    'Octobre','Novembre','Décembre'];
    return monthList[month];
  };
  const nicePeriod = (startDate: string, endDate: string) => {
    if (startDate === null)
        return '';
    const start = new Date(startDate);
    const end = new Date(endDate);
    if(start.getMonth() === end.getMonth() && start.getDate() < end.getDate())
    {
        return `${start.getDate()} au ${end.getDate()} ${monthToString(start.getMonth())}`;
    }
    return '';
  };

//const context = useContext(GlobalContext);
//const registerDispatch = context?.registerDispatch;


const RegistrationForm = ({stages, courses, title}:IProps) => {
    const [form, setForm] = useState<IRegistrationForm>(defaultRegistrationForm);
    // const [error, setError] = useState(undefined);
    const [course, setCourse] = useState("Choisir votre cours");
    const [stage, setStage] = useState("Choisir votre stage");
    const [isAChild, setIsAChild] = useState<boolean>(true);

    const maxSubscriptions = 14;
    // useEffect(() => {
    // });
    console.log("form: ",form);
    console.log("course: ",course);
    console.log("stage: ",stage);

    const onSubmit = (e: React.FormEvent, form: any) => {
        e.preventDefault();
        // if (form.emailAddress && !hasEmailError && !hasPasswordError) {
        //   registerUser(form)(registerDispatch);
        // }
        console.log("form", form);
         registerUser(form);
        //alert("Le site est actuellement en maintenance. Veuillez appeler le 0473/19 94 70");
        // alert("Votre demande d'inscription a été envoyée avec succès. Vous recevrez bientôt un e-mail avec le devis.");
        alert("Merci pour votre inscription! Nous vous confirmons celle-ci dans les plus brefs délais en fonction des places disponibles pour le.s cours sélectionné.s.");
    };

    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    const hasEmailError = !(pattern.test(form.emailAddress1) && pattern.test(form.emailAddress2));
    const emailError = (): string => {
        if (!(form.emailAddress1 || form.emailAddress2))
            return "";
            if (form.emailAddress1 && !pattern.test(form.emailAddress1))
            return "La première adresses e-mail n'est pas valide";
            if (form.emailAddress2 && !pattern.test(form.emailAddress2))
            return "La deuxième adresses e-mail n'est pas valide";
        return "a";
    };
    //   const hasPasswordError = form.password !== form.ConfirmPswd;

    const onChange = (e: React.FormEvent<HTMLInputElement>): void => {
        // setError(undefined);
        setForm({ ...form, [e.currentTarget.id]: e.currentTarget.value });
    };

    const updateCourse: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setCourse(e.target.value);
        setForm({ ...form, [e.target.id]: e.target.value });
      };

      const updateStage: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setStage(e.target.value);
        setForm({ ...form, [e.target.id]: e.target.value });
      };

    //   const onChangeIsAchild = (e: React.FormEvent<HTMLInputElement>): void => {
    //     setIsAChild(!isAChild)
    // };

    

    return (
        <Form className="registration-form content-width" method="post" onSubmit={(e) => onSubmit(e, form)} >
            <h2>Merci de remplir votre fiche de pré-inscription : </h2>
            <div className="registration-form-course" >
            {courses !== undefined ?
                <select name="course" id="course" value={course} onChange={updateCourse}>
                <option key='default' value=''>{title}</option>
                {
                    courses.filter((li) => li.course.isVisible && li.subscribes.length < maxSubscriptions).map((li) =>{
                        const schedule = li.course.day?.name !== '' ? ` (${li.course.day?.name} de ${niceTime(li.course.startTime)} à ${niceTime(li.course.endTime)})`  : '';
                        return (
                            <option key={li.course.shortName} value={li.course.shortName}>{li.course.name}{schedule}</option>
                        );
                    })
                }
            </select>
            : <div/>
            }
                {stages !== undefined ?
                <select name="stage" id="stage" value={stage} onChange={updateStage}>
                <option key='default' value=''>{title}</option>
                {
                    stages.map((li) =>{
                        const schedule = li.startDate !== undefined ? ` (Du ${nicePeriod(li.startDate,li.endDate)})`  : '';
                        return (
                            <option key={li.shortName} value={li.shortName}>{li.description}{schedule}</option>
                        );
                    })
                }
            </select>
            : <div/>
            }
            </div>
            
            <div className="registration-form-is-a-child">
            <Label className="checkbox" htmlFor="isachild">
                    <Input type="checkbox" onClick={() => setIsAChild(!isAChild)} checked={isAChild} id="isachild" name="isachild" />
                J'inscris mon enfant
              </Label>
            </div>

            <div className="registration-form-name registration-form-item">
                <Label>Prénom et nom (de l'enfant)<span className="mandatory">*</span></Label>
                <div className="flex">
                    <div className="registration-form-name__first-name">
                        <Input type="text" id="firstName" name="firstName" autoComplete="first-name" onChange={onChange} placeholder="Prénom" />
                        {/* <p>Prénom</p> */}
                    </div>
                    <div className="registration-form-name__last-name">
                        <Input type="text" id="lastName" name="lastName" autoComplete="family-name" onChange={onChange} placeholder="Nom" />
                        {/* <p>Nom</p> */}
                    </div>
                </div>
            </div>
            <div className="registration-form-birthdate registration-form-item">
                <Label>Date de naissance (de l'enfant)<span className="mandatory">*</span></Label>
                <Input type="text" id="birthDate" name="birthDate" autoComplete="date" onChange={onChange} />
            </div>
            <div className="flex space-between addresses registration-form-item">
                <div className="registration-form-address">
                <Label>Adresse<span className="mandatory">*</span></Label>
                    <div className="flex">
                        <p>Rue : </p>
                        <Input type="text" id="street" name="street" autoComplete="street" onChange={onChange} />                        
                    </div>
                    <div className="flex">
                        <p>Code postal : </p>
                        <Input type="text" id="postalCode" name="postalCode" autoComplete="postalCode" onChange={onChange} />                        
                    </div>
                    <div className="flex">
                        <p>Ville : </p>
                        <Input type="text" id="city" name="city" autoComplete="city" onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="registration-form-phone registration-form-item">
                <Label>Numéro de téléphone<span className="mandatory">*</span></Label>
                <Input type="text" id="phoneNumber" name="phoneNumber" autoComplete="phone" onChange={onChange} />
            </div>
            {isAChild ?
            <div className="registration-form-email registration-form-item">
            <Label>Adresses e-mail<span className="mandatory">*</span></Label>
            <div className="flex">
                <p>Parent 1 : </p>
                <Input type="text" className="emailAddress" id="emailAddress1" name="emailAddress1" autoComplete="email" onChange={onChange} />
            </div>
            <div className="flex">
                <p>Parent 2 : </p>
                <Input type="text" className="emailAddress" id="emailAddress2" name="emailAddress2" autoComplete="email" onChange={onChange} />
            </div>
            {/* placeholder="Email-address" */}
            {hasEmailError ?
                <Label id="emailError" name="emailError" className="errors">
                    {emailError()}
                </Label>
                : <span />
            }
        </div>
        :
        <div className="registration-form-email registration-form-item">
        <Label>Adresses e-mail<span className="mandatory">*</span></Label>
            <Input type="text" className="emailAddress" id="emailAddress" name="emailAddress1" autoComplete="email" onChange={onChange} />
        {/* placeholder="Email-address" */}
        {hasEmailError ?
            <Label id="emailError" name="emailError" className="errors">
                {emailError()}
            </Label>
            : <span />
        }
    </div>

        }
            <div className="registration-form-submit">
                <Button
                    className='button pointer'
                    //   disabled={loading}
                    type="submit"
                //   loading={loading.toString()}
                >
                    {/* <i className="fa fa-spinner fa-spin"></i> */}
                    <span className="text">S'inscrire</span>
                </Button>
                {/* <Input type="button" value="Soumettre" /> */}
            </div>
        </Form>
    )
}

export default RegistrationForm;

