import React from 'react';
// import { Link } from 'react-router-dom';
import '../css/home.scss';

const Home = () => {

  return (
    <div id="home">
      <div id="title">
        <h1>Accueil</h1>
      </div>
      {/* <TileLayout /> */}
      <div id="content">
        <h2>Bonjour à toutes et à tous,</h2>

        
        <p>Les documents pour l'année 2024-2025 :</p>
      <div id="documents">
        <ul>
          <a target="_blank" rel="noopener noreferrer" download href="https://creacirque.usidistrib.com/Documents/Cr%C3%A9acirque%20-%20Droits%20image.pdf">
            <li className="documents__item">Droits à l'image</li>
          </a>
          <a target="_blank" rel="noopener noreferrer" download href="https://creacirque.usidistrib.com/Documents/Cr%C3%A9acirque%20-%20Licences.pdf">
            <li className="documents__item">Licences</li>
          </a>
          <a target="_blank" rel="noopener noreferrer" download href="https://creacirque.usidistrib.com/Documents/Cr%C3%A9acirque%20-%20Informations%20complémentaires.pdf">
            <li className="documents__item">Informations complémentaires</li>
          </a>
        </ul>
      </div>

<h3>Les spectacles de fin d'année se dérouleront en juin 2025.</h3>
<h4>Pour nos stages d'été 2024, Créacirque s'associe avec de nombreuses associations et lieux culturels afin de mélanger le cirque avec d'autres disciplines artistiques et créer des ponts entre les arts!</h4>
<h5>Venez les découvrir dans notre onglet "stages"!</h5>
<h5>Bienvenue sur le site de CREACIRQUE, l’Ecole de Cirque de Namur !</h5>
<p>Toutes nos activités se donnent dans la salle 2 de l'Institut Ste-Marie (227, rue de l'Aurore à 5100 Jambes).</p>
<p>L'envie qui nous anime est le développement des arts du cirque à travers la créativité,le partage, la perfomance et l'esthétisme.</p>
<p>N'hésitez à aller voir nos photos qui mettent en valeur le travail de nos talentueux élèves.</p>
<h4>Nouveau cours de PP (préparation physique) de cirque/gym pour adultes et ados tous les mercredis de 17h15 à 18h15</h4>
<div>Vous qui avez toujours révé de:
<ul>
<li>
  Marcher sur les mains.
  </li>
  <li>
  Rester une minute sans bouger sur les mains, la tête ou sur un bras.
  </li>
  <li>
  Vous relerver sans soucis d'un rétablissement grâce au "muscle up" (barres ou anneaux).
  </li>
  <li>
  Vous releverdu trépiedvers le porrieret de vous renforcer avec votre poids de corps(gainages spécifiques, abdos en dynamisme, positions en équilibre...).
  </li>
</ul>
</div>
<p>Une vraie préparation physique dans un local attentif aux mesures Covid.</p>
<p>Le tout dans une ambiance cool, appliquée, bienveillante et encadré par un professionnel.</p>
<p>Intérressé.e.? Le 1 er cours est gratuit, nous vous attendons impatiemment!</p>
      </div>
    </div>
  )
};

export default Home;
