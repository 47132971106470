import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import RegistrationForm from '../components/RegistrationForm';
import axiosInstance from "../helpers/Axios";


const defaultList: IStage[] = []
// ({shortName:"", name:"Choisir votre stage", description: "choix", startDate:'2024-08-13', endDate:'2024-08-15', startTime:'08:00:00', endTime:'16:00:00'}),
// ({shortName:"2", name:"F", description: "ff", startDate:'2024-08-16', endDate:'2024-08-18', startTime:'08:00:00', endTime:'16:00:00'}),
// ({shortName:"3", name:"G", description: "gg", startDate:'2024-08-19', endDate:'2024-08-22', startTime:'08:00:00', endTime:'16:00:00'})];

interface IStage {
  shortName: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}

interface IStageObj {
  stage: IStage;
}

const Stages = () => {

  const [list, setList] = useState(defaultList);
  const [visible, setVisible] = useState(true);
  const loading = list === defaultList && visible;

  useEffect(() => {
    if(loading)
      axiosInstance.get("/stage")
        .then(response => {
          //console.log('response', response);
  
          setList(response.data.map((stageObj : IStageObj) => stageObj.stage))
          // setUserProfile(response.data);
          // setLocation(response.data.location);
        })
        .catch((err) => {
          const error = err.response === undefined ?
            'Server in not reachable'
            : err.response.data;
          console.log('error', error);
          setVisible(false);
        });
      }
   );
  
  return (
    <div id="stages">
      <div id="title">
        <h1>Stages<br/> WEEKEND-END</h1>
      </div>
      <div id="content">
      {loading ? 
        <p>Chargement en cours...</p>
        :
      visible ? 
        <RegistrationForm stages={list} courses={undefined} title="Choisir votre stage" />
        :
        <div>
          <p>Le système d'inscription est actuellement en panne.</p>
        </div>
    }
      </div>
    </div>
  )
};

export default Stages;
